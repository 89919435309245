import { zodResolver } from '@hookform/resolvers/zod';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { AttachmentsInput, PlantSelect, UserAutocomplete } from '@top-solution/microtecnica-mui';
import type { ComponentProps } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type ECRForm, ECRFormSchema } from '../../entities/ECR';
import { useUploadMutation } from '../../services/attachmentsApi';
import { DepartmentSelect, DocumentTypeSelect, PrioritySelect, RequestTypeSelect } from '../inputs';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(1),
  paddingBlockStart: theme.spacing(1),

  '>*': {
    flex: '1 1 100%',
  },

  [theme.breakpoints.up('md')]: {
    [`
      .field-plant,
      .field-department
    `]: {
      flex: '1 1 45%',
    },
    [`
      .field-document_id,
      .field-reported_by
    `]: {
      flex: '1 1 40%',
    },
    [`
      .field-document_revision,
      .field-document_type,
      .field-request_type,
      .field-priority
    `]: {
      flex: '1 1 25%',
    },
  },
}));

type ECREditFormProps = Omit<ComponentProps<'form'>, 'onSubmit' | 'children'> & {
  initialValues: ECRForm;
  onSubmit: (data: ECRForm) => void;
};

export function ECREditForm(props: ECREditFormProps): JSX.Element {
  const { initialValues, onSubmit, ...formProps } = props;
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<ECRForm>({
    defaultValues: initialValues,
    resolver: zodResolver(ECRFormSchema),
  });

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit, console.error)} {...formProps}>
      <Controller
        control={control}
        name="plantId"
        render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
          <PlantSelect
            className="field-plant"
            label={t('ecr.field.plant')}
            value={value ?? ''}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="departmentId"
        render={({ field, fieldState: { invalid, error } }) => (
          <DepartmentSelect
            className="field-department"
            label={t('ecr.field.department')}
            error={invalid}
            helperText={error?.message ?? ' '}
            disableClearable
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="documentId"
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            className="field-document_id"
            label={t('ecr.field.document_id')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="documentRevision"
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            className="field-document_revision"
            label={t('ecr.field.document_revision')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="documentTypeId"
        render={({ field, fieldState: { invalid, error } }) => (
          <DocumentTypeSelect
            className="field-document_type"
            label={t('ecr.field.document_type')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="reportedBy"
        render={({ field: { onChange, ...field }, fieldState: { invalid, error } }) => (
          <UserAutocomplete
            className="field-reported_by"
            label={t('ecr.field.reported_by')}
            onChange={(_, newValue) => onChange(newValue)}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="requestTypeId"
        render={({ field, fieldState: { invalid, error } }) => (
          <RequestTypeSelect
            className="field-request_type"
            label={t('ecr.field.request_type')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="priorityId"
        render={({ field, fieldState: { invalid, error } }) => (
          <PrioritySelect
            className="field-priority"
            label={t('ecr.field.priority')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="reason"
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            multiline
            minRows={2}
            className="field-reason"
            label={t('ecr.field.reason')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="request"
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            multiline
            minRows={2}
            className="field-request"
            label={t('ecr.field.request')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="attachmentList"
        render={({ field, fieldState: { invalid, error } }) => (
          <AttachmentsInput
            uploadMutation={useUploadMutation}
            className="field-attachment_list"
            size="small"
            label={t('ecr.field.attachment_list')}
            error={invalid}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
    </StyledForm>
  );
}
