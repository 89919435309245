import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { ErrorAlert, FullPageProgress } from '@top-solution/microtecnica-mui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import type { ECRAnalysis } from '../../entities/ECRAnalysis';
import { useAnalyseECRMutation, useReadECRQuery } from '../../services/ecrApi';
import { ECRAnalyseForm } from './ECRAnalyseForm';
import { ECRAnalyseFormMode } from './ECRAnalyseFormMode';
import { ECRData } from './ECRData';

const formId = 'ecr-analyse-form';

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(2),
  },

  '& > *': {
    flex: 1,
  },
}));

type ECRAnalyseDialogProps = Omit<DialogProps, 'onClose'> & {
  ecr: ECR;
  onClose: () => void;
};

export function ECRAnalyseDialog(props: ECRAnalyseDialogProps): JSX.Element {
  const { ecr, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const readECRDetails = useReadECRQuery(ecr.id);
  const [analyse, analyseStatus] = useAnalyseECRMutation();

  const handleSubmit = useCallback(
    async (data: ECRAnalysis) => {
      await analyse({
        id: ecr.id,
        analyses: data.analyses,
      }).unwrap();
      onClose();
    },
    [analyse, ecr, onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          analyseStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('ecr.action.analyse')}</DialogTitle>
      <DialogContent>
        {readECRDetails.isLoading ? (
          <FullPageProgress height={450} />
        ) : readECRDetails.error ? (
          <ErrorAlert error={readECRDetails.error} />
        ) : (
          readECRDetails.data && (
            <FormWrapper>
              <ECRData ecr={readECRDetails.data} sx={{ marginBlockStart: 1.5 }} />
              <ECRAnalyseForm
                id={formId}
                data={readECRDetails.data}
                onSubmit={handleSubmit}
                mode={ECRAnalyseFormMode.ANALYSIS}
              />
            </FormWrapper>
          )
        )}
        {analyseStatus.error && <ErrorAlert error={analyseStatus.error} sx={{ marginTop: 1 }} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          {t('action.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          form={formId}
          loading={analyseStatus.isLoading}
          disabled={readECRDetails.isLoading}
        >
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
