import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import type { SxProps, Theme } from '@mui/material/styles';
import { IconButton } from '@top-solution/microtecnica-mui';
import { formatDateTime, useAuth } from '@top-solution/microtecnica-utils';
import { type ComponentProps, useMemo } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ECRAnalysis } from '../../../entities/ECRAnalysis';
import { ECRUserRole } from '../../../entities/User';
import { useUserHasRole } from '../../../hooks/useACL';
import { useEquipmentMap } from '../../../hooks/useEquipmentMap';
import { RemoveIcon } from '../../Icons';
import { ResponsiveRow } from '../../Layout';
import { Fieldset, ValueStreamSelect } from '../../inputs';
import { ECRAnalyseFormMode } from '../ECRAnalyseFormMode';
import { EquipmentAutocompleteWithManagerLabel } from './EquipmentAutocompleteWithManagerLabel';

type AnalysisFieldsetProps = Omit<ComponentProps<'fieldset'>, 'form' | 'children'> & {
  form: UseFormReturn<ECRAnalysis>;
  analysis: ECRAnalysis['analyses'][0];
  index: number;
  mode: ECRAnalyseFormMode;
  sx?: SxProps<Theme>;
};

export function AnalysisFieldset(props: AnalysisFieldsetProps): JSX.Element {
  const { form, index, analysis, mode, disabled, sx } = props;
  const { t } = useTranslation();
  const { username } = useAuth();

  const { map } = useEquipmentMap();
  const userHasRole = useUserHasRole();

  const { canAssign, canAnalyse, canClearAnalysis } = useMemo(() => {
    const isTechnicalResponsible = map?.get(analysis.equipmentCode)?.username === username;
    const canAssign = !disabled && mode === ECRAnalyseFormMode.ASSIGNMENT && analysis.id === undefined;
    const canAnalyse = !disabled && mode === ECRAnalyseFormMode.ANALYSIS && isTechnicalResponsible;
    const canClearAnalysis =
      !disabled &&
      mode === ECRAnalyseFormMode.ANALYSIS &&
      analysis.accepted !== null &&
      (isTechnicalResponsible || userHasRole([ECRUserRole.CONFIGURATION_CONTROL, ECRUserRole.ADMIN]));
    return {
      canAssign,
      canAnalyse,
      canClearAnalysis,
    };
  }, [analysis, mode, disabled, map, username, userHasRole]);

  return (
    <Fieldset sx={sx} disabled={disabled}>
      <legend>{t('ecr.field.analysis_index', { index: index + 1 })}</legend>
      <ResponsiveRow rowUp="lg">
        <Controller
          control={form.control}
          name={`analyses.${index}.equipmentCode`}
          render={({ field: { value, onChange, ...field }, fieldState: { invalid, error } }) => (
            <EquipmentAutocompleteWithManagerLabel
              value={value}
              onChange={(_, value) => onChange(value)}
              className="field-equipment-code"
              label={t('equipment.field.equipment_code')}
              error={invalid}
              helperText={error?.message}
              disabled={!canAssign}
              {...field}
            />
          )}
        />
        <Controller
          control={form.control}
          name={`analyses.${index}.valueStreamId`}
          render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
            <ValueStreamSelect
              className="field-value-stream"
              label={t('equipment.field.value_stream')}
              value={value ?? ''}
              error={invalid}
              helperText={error?.message}
              disabled={!canAssign}
              {...field}
            />
          )}
        />
        {!disabled && mode === ECRAnalyseFormMode.ASSIGNMENT && !analysis.accepted && (
          <IconButton
            className="remove-equipment"
            size="small"
            title={t('equipment.action.remove')}
            onClick={() => {
              form.setValue(
                'analyses',
                form.getValues('analyses').filter((_, i) => i !== index),
              );
            }}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </ResponsiveRow>
      {(canAnalyse || analysis.accepted !== null) && (
        <>
          <ResponsiveRow sx={{ marginBlock: 1 }}>
            <Controller
              control={form.control}
              name={`analyses.${index}.accepted`}
              render={({ field: { value, onChange, ...field }, fieldState: { invalid, error } }) => (
                <TextField
                  select
                  className="field-accepted"
                  label={t('equipment.field.accepted')}
                  value={value === true ? 'accepted' : value === false ? 'rejected' : ''}
                  onChange={(e) => onChange(e.target.value === 'accepted')}
                  error={invalid}
                  helperText={error?.message}
                  disabled={!canAnalyse}
                  {...field}
                >
                  {typeof value !== 'boolean' && <MenuItem value="">&nbsp;</MenuItem>}
                  <MenuItem value="accepted">{t('status.accepted')}</MenuItem>
                  <MenuItem value="rejected">{t('status.rejected')}</MenuItem>
                </TextField>
              )}
            />

            <TextField
              className="field-analyzed-at"
              label={t('equipment.field.analyzed_at')}
              value={analysis.analyzedAt ? formatDateTime(analysis.analyzedAt) : ''}
              disabled
            />

            {canClearAnalysis && (
              <IconButton
                className="remove-analysis"
                title={t('equipment.action.removeAnalysis')}
                onClick={() => {
                  form.setValue(`analyses.${index}.accepted`, null);
                  form.setValue(`analyses.${index}.analyzedAt`, null);
                  form.setValue(`analyses.${index}.assessment`, null);
                }}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </ResponsiveRow>
          <Controller
            control={form.control}
            name={`analyses.${index}.assessment`}
            render={({ field: { value, ...field }, fieldState: { error, invalid } }) => (
              <TextField
                fullWidth
                className="field-assessment"
                label={t('equipment.field.assessment')}
                value={value ?? ''}
                multiline
                minRows={2}
                maxRows={5}
                error={invalid}
                helperText={error?.message}
                disabled={!canAnalyse}
                {...field}
              />
            )}
          />
        </>
      )}
    </Fieldset>
  );
}
