import { styled } from '@mui/material/styles';

export const Fieldset = styled('fieldset')(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderWidth: 'thin 0 0',
  margin: 0,
  padding: theme.spacing(1, 0, 0),

  '& > legend': {
    ...theme.typography.subtitle2,
    margin: theme.spacing(0, 0.5, 0, 1.5),
  },

  '&[disabled] > legend': {
    color: theme.palette.text.disabled,
  },
}));
