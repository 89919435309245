import type { TextFieldProps } from '@mui/material/TextField';
import { EntitySelect } from '@top-solution/microtecnica-mui';
import { type Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadDocumentTypeListQuery } from '../../services/documentTypeApi';

type DocumentTypeSelectProps = Omit<TextFieldProps, 'select' | 'children'>;

export const DocumentTypeSelect = forwardRef(function DocumentTypeSelect(
  props: DocumentTypeSelectProps,
  ref: Ref<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const readDocumentTypeList = useReadDocumentTypeListQuery();

  return (
    <EntitySelect
      ref={ref}
      options={readDocumentTypeList.data}
      fetchError={readDocumentTypeList.isError}
      fetchInProgress={readDocumentTypeList.isFetching}
      getValue={(item) => item.id}
      getLabel={(item) => t(`document_type.${item.name}`)}
      {...props}
    />
  );
});
