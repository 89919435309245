import { type ValueStream, ValueStreamSchema } from '../entities/ValueStream';
import { TAG_TYPES, api } from './baseApi';

const url = 'v1/value-stream';
const tags = [{ type: TAG_TYPES.VALUE_STREAM, id: 'LIST' }];

const valueStreamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readValueStreamList: builder.query<ValueStream[], void>({
      query: () => ({ url }),
      transformResponse: (data) => ValueStreamSchema.array().parse(data),
      providesTags: tags,
    }),
    updateValueStream: builder.mutation<void, ValueStream>({
      query: ({ id, ...body }) => ({
        url: `${url}/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: tags,
    }),
  }),
  overrideExisting: false,
});

export const { useReadValueStreamListQuery, useUpdateValueStreamMutation } = valueStreamApi;
