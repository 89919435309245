import Skeleton from '@mui/material/Skeleton';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Department } from '../../../entities/Department';
import { useReadDepartmentListQuery } from '../../../services/departmentApi';

type DepartmentLabelProps = {
  departmentId: Department['id'];
};

export function DepartmentLabel({ departmentId }: DepartmentLabelProps): ReactNode {
  const { t } = useTranslation();
  const readDepartmentList = useReadDepartmentListQuery();

  const department = useMemo(
    () => readDepartmentList.data?.find((department) => department.id === departmentId),
    [departmentId, readDepartmentList.data],
  );

  if (readDepartmentList.isLoading) {
    return <Skeleton variant="text" width="10ch" />;
  }
  return t(`department.${department?.name ?? departmentId}`);
}
