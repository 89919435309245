import { type Breakpoint, styled } from '@mui/material/styles';

type ResponsiveRowProps = {
  rowUp?: Breakpoint | number;
};

export const ResponsiveRow = styled('div')<ResponsiveRowProps>(({ theme, rowUp }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(1),

  [theme.breakpoints.up(rowUp ?? 'md')]: {
    flexFlow: 'row wrap',
    alignItems: 'flex-start',

    '> *': {
      flex: '1 0 0%',
    },
  },
}));
