import {
  mdiAccountMultiple,
  mdiAccountPlus,
  mdiMagnifyExpand,
  mdiOrderBoolDescendingVariant,
  mdiPencil,
  mdiPlusCircle,
  mdiRefreshCircle,
  mdiStateMachine,
  mdiTrashCan,
} from '@mdi/js';
import { createMdiIcon } from '@top-solution/microtecnica-mui';

export const AnalyseIcon = createMdiIcon(mdiOrderBoolDescendingVariant);
export const AssignIcon = createMdiIcon(mdiAccountPlus);
export const ChangeStatusIcon = createMdiIcon(mdiStateMachine);
export const CreateIcon = createMdiIcon(mdiPlusCircle);
export const DetailsIcon = createMdiIcon(mdiMagnifyExpand);
export const RemoveIcon = createMdiIcon(mdiTrashCan);
export const ReopenIcon = createMdiIcon(mdiRefreshCircle);
export const UpdateIcon = createMdiIcon(mdiPencil);
export const UserManagementIcon = createMdiIcon(mdiAccountMultiple);
