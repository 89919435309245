import { useAuth } from '@top-solution/microtecnica-utils';
import { useCallback, useMemo } from 'react';
import { type ECR, ECRStatus } from '../entities/ECR';
import { ECRUserRole } from '../entities/User';

type UseACL = {
  canAddECR: boolean;
  canManageECR: (ecr: ECR) => boolean;
  canAssignECR: (ecr: ECR) => boolean;
  canReopenECR: (ecr: ECR) => boolean;
  canAnalyseECR: (ecr: ECR) => boolean;
  canChangeECRStatus: (ecr: ECR) => boolean;
  canClearECRStatus: (ecr: ECR) => boolean;
  canManageUsers: boolean;
};

export function useUserHasRole(): (roles: string[]) => boolean {
  const { appRoleMap } = useAuth();
  const userRoles = appRoleMap.get(import.meta.env.VITE_APP_ID);

  return useCallback(
    (roles: string[]) => {
      if (userRoles) {
        return roles.some((role) => userRoles.has(role));
      }
      return false;
    },
    [userRoles],
  );
}

export function useACL(): UseACL {
  const { username } = useAuth();

  const userHasRole = useUserHasRole();

  return useMemo(
    () => ({
      canAddECR: userHasRole([
        ECRUserRole.ADMIN,
        ECRUserRole.ENGINEERING,
        ECRUserRole.QUALITY,
        ECRUserRole.SUPPLY_CHAIN,
        ECRUserRole.MANUFACTURING,
      ]),
      canManageECR: (ecr: ECR) =>
        [ECRStatus.OPEN].includes(ecr.status) && (userHasRole([ECRUserRole.ADMIN]) || ecr.openBy === username),
      canReopenECR: (ecr: ECR) =>
        [ECRStatus.REJECTED].includes(ecr.status) &&
        userHasRole([ECRUserRole.CONFIGURATION_CONTROL, ECRUserRole.ADMIN]),
      canAssignECR: (ecr: ECR) =>
        [ECRStatus.OPEN, ECRStatus.REJECTED, ECRStatus.ASSIGNED, ECRStatus.ACCEPTED].includes(ecr.status) &&
        userHasRole([ECRUserRole.CONFIGURATION_CONTROL, ECRUserRole.ADMIN]),
      canAnalyseECR: (ecr: ECR) => [ECRStatus.ASSIGNED, ECRStatus.ACCEPTED].includes(ecr.status),
      canChangeECRStatus: (ecr: ECR) =>
        [ECRStatus.ACCEPTED, ECRStatus.ASSIGNED].includes(ecr.status) &&
        userHasRole([ECRUserRole.CONFIGURATION_CONTROL, ECRUserRole.ADMIN]),
      canClearECRStatus: (ecr: ECR) =>
        [ECRStatus.CLOSED, ECRStatus.SUSPENDED].includes(ecr.status) &&
        userHasRole([ECRUserRole.CONFIGURATION_CONTROL, ECRUserRole.ADMIN]),
      canManageUsers: userHasRole([ECRUserRole.ADMIN]),
    }),
    [username, userHasRole],
  );
}
