import type { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useReadRequestTypeListQuery } from '../../services/requestTypeApi';

export function useRequestTypeColDef(): GridSingleSelectColDef<ECR, ECR['requestTypeId'], string> {
  const { data, isLoading } = useReadRequestTypeListQuery();
  const { t } = useTranslation();

  return useMemo(
    () => ({
      field: 'requestTypeId',
      headerName: t('ecr.field.request_type'),
      type: 'singleSelect',
      valueOptions: data?.map(({ id, name }) => ({ value: id, label: t(`request_type.${name}`) })) ?? [],
      filterable: !isLoading,
      width: 140,
    }),
    [data, isLoading, t],
  );
}
