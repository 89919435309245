import { UnauthorizedBox } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';

interface UnauthorizedPageProps {
  description?: string;
}

export function UnauthorizedPage({ description }: UnauthorizedPageProps): JSX.Element {
  const { t } = useTranslation();
  const title = t('error.unauthorized');
  return (
    <Layout title={title} maxWidth="sm">
      <UnauthorizedBox title={title} description={description} />
    </Layout>
  );
}
