import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { ErrorAlert, FullPageProgress } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useReadECRQuery } from '../../services/ecrApi';
import { ECRAnalysis, ECRData } from './ECRData';

const DataWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(2),
  },

  '& > *': {
    flex: 1,
  },
}));

type ECRDetailsDialogProps = Omit<DialogProps, 'onClose'> & {
  ecr: ECR;
  onClose: () => void;
};

export function ECRDetailsDialog(props: ECRDetailsDialogProps): JSX.Element {
  const { ecr, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const readECRDetails = useReadECRQuery(ecr.id);

  return (
    <Dialog
      fullWidth
      maxWidth={ecr.assignedAt ? 'xl' : 'md'}
      onClose={onClose}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('ecr.action.details')}</DialogTitle>
      <DialogContent>
        {readECRDetails.isLoading ? (
          <FullPageProgress height={450} />
        ) : readECRDetails.error ? (
          <ErrorAlert error={readECRDetails.error} />
        ) : (
          readECRDetails.data && (
            <DataWrapper>
              <ECRData ecr={readECRDetails.data} displayChangeProposal displayPreAnalysis />
              {readECRDetails.data?.analyses?.length && <ECRAnalysis ecr={readECRDetails.data} />}
            </DataWrapper>
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          {t('action.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
