import { ConfirmDialog, type ConfirmDialogProps } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useClearECRStatusMutation } from '../../services/ecrApi';

export type ECRClearStatusDialogProps = Omit<
  ConfirmDialogProps,
  'title' | 'confirmText' | 'inProgress' | 'error' | 'onConfirm'
> & {
  ecr: ECR | null;
};

export function ECRClearStatusDialog(props: ECRClearStatusDialogProps): JSX.Element {
  const { ecr, open, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const [clear, clearStatus] = useClearECRStatusMutation();

  return (
    <ConfirmDialog
      fullWidth
      maxWidth="sm"
      title={t('ecr.clear_status.confirm.title', { id: ecr?.id })}
      description={t('ecr.clear_status.confirm.description')}
      confirmText={ecr?.id.toString()}
      inProgress={clearStatus.isLoading}
      error={clearStatus.error}
      open={open}
      onClose={(e) => onClose(e, 'cancelClick')}
      onConfirm={async (e) => {
        if (ecr) {
          await clear(ecr).unwrap();
          onClose(e, 'submitClick');
        }
      }}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          clearStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    />
  );
}
