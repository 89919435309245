import { useMemo } from 'react';
import type { Equipment } from '../entities/Equipment';
import { useReadEquipmentListQuery } from '../services/equipmentApi';

export type UseEquipmentMap = ReturnType<typeof useReadEquipmentListQuery> & {
  map: Map<Equipment['id'], Equipment> | undefined;
};

export function useEquipmentMap(): UseEquipmentMap {
  const readEquipmentList = useReadEquipmentListQuery();

  const usernameByEquipment = useMemo(
    () =>
      readEquipmentList.data
        ? new Map(readEquipmentList.data.map((equipment) => [equipment.id, equipment]))
        : undefined,
    [readEquipmentList.data],
  );

  return {
    ...readEquipmentList,
    map: usernameByEquipment,
  };
}
