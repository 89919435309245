import { z } from 'zod';
import { type Analysis, AnalysisSchema } from './Analysis';

export const MAX_ANALYSES = 10;

export const ECRAnalysisSchema = z
  .object({
    preAnalysis: z.object({
      accepted: z.boolean(),
      reason: z.string().max(4000).nullable(),
    }),
    analyses: z
      .object({
        id: AnalysisSchema.shape.id.optional(),
        equipmentCode: AnalysisSchema.shape.equipmentCode,
        valueStreamId: AnalysisSchema.shape.valueStreamId,
        assessment: AnalysisSchema.shape.assessment.unwrap().nullable(),
        analyzedAt: AnalysisSchema.shape.analyzedAt.unwrap().nullable(),
        accepted: AnalysisSchema.shape.accepted.unwrap().nullable(),
      })
      .array()
      .max(MAX_ANALYSES),
  })
  .superRefine((data, ctx) => {
    if (!data.preAnalysis.accepted && (data.preAnalysis.reason?.length ?? 0) < 3) {
      ctx.addIssue({
        path: ['preAnalysis', 'reason'],
        code: z.ZodIssueCode.too_small,
        type: 'string',
        inclusive: true,
        minimum: 3,
      });
    }
    if (data.preAnalysis.accepted) {
      if (data.analyses.length > 0) {
        const indexByEquipmentCode = new Map<Analysis['equipmentCode'], number>();
        for (let i = 0; i < data.analyses.length; i++) {
          const analysis = data.analyses[i];
          if (indexByEquipmentCode.has(analysis.equipmentCode)) {
            ctx.addIssue({
              path: ['analyses', i, 'equipmentCode'],
              code: z.ZodIssueCode.custom,
              message: `Alread defined in #${(indexByEquipmentCode.get(analysis.equipmentCode) ?? 0) + 1}`,
            });
          } else {
            indexByEquipmentCode.set(analysis.equipmentCode, i);
          }
          // Accepted can ben `null` so we need to check if it's `false`
          if (analysis.accepted === false && (analysis.assessment?.length ?? 0) < 3) {
            ctx.addIssue({
              path: ['analyses', i, 'assessment'],
              code: z.ZodIssueCode.too_small,
              type: 'string',
              inclusive: true,
              minimum: 3,
            });
          }
        }
      } else {
        ctx.addIssue({
          path: ['analyses'],
          code: z.ZodIssueCode.too_small,
          type: 'array',
          inclusive: true,
          minimum: 1,
        });
      }
    }
  });

export type ECRAnalysis = z.infer<typeof ECRAnalysisSchema>;
