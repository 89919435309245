import type { TextFieldProps } from '@mui/material/TextField';
import { EntitySelect } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { type Ref, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ECRUserRole } from '../../entities/User';
import { useReadDepartmentListQuery } from '../../services/departmentApi';

type DepartmentSelectProps = Omit<TextFieldProps, 'select' | 'children'> & {
  disableClearable?: boolean;
};

export const DepartmentSelect = forwardRef(function DepartmentSelect(
  props: DepartmentSelectProps,
  ref: Ref<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const readDepartmentList = useReadDepartmentListQuery();
  const { appRoleMap } = useAuth();

  const options = useMemo(() => {
    if (readDepartmentList.data) {
      const userRoles = appRoleMap.get(import.meta.env.VITE_APP_ID);

      return readDepartmentList.data.filter(({ name }) => {
        if (userRoles?.has(ECRUserRole.ADMIN)) {
          return true;
        }
        if (name === 'engineering') {
          return userRoles?.has(ECRUserRole.ENGINEERING);
        }
        if (name === 'supply_chain') {
          return userRoles?.has(ECRUserRole.SUPPLY_CHAIN);
        }
        if (name === 'manufacturing_engineering') {
          return userRoles?.has(ECRUserRole.MANUFACTURING);
        }
        if (name === 'quality') {
          return userRoles?.has(ECRUserRole.QUALITY);
        }
        return true;
      });
    }
    return [];
  }, [readDepartmentList.data, appRoleMap]);

  return (
    <EntitySelect
      ref={ref}
      options={options}
      fetchError={readDepartmentList.isError}
      fetchInProgress={readDepartmentList.isFetching}
      getValue={(item) => item.id}
      getLabel={(item) => t(`department.${item.name}`)}
      {...props}
    />
  );
});
