import { zodResolver } from '@hookform/resolvers/zod';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import type { ComponentProps } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type ECRDetails, ECRStatus } from '../../entities/ECR';
import { type ECRChangeStatus, ECRChangeStatusSchema } from '../../entities/ECRChangeStatus';
import { ResponsiveRow } from '../Layout';
import { Fieldset } from '../inputs';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(1),
  paddingBlockStart: theme.spacing(1),

  '>*': {
    flex: '1 1 100%',
  },

  [theme.breakpoints.up('md')]: {
    [['.field-change_proposal', '.field-status'].join(' ')]: {
      flex: '1 1 45%',
    },
    [['.field-status_comments'].join(' ')]: {
      flex: '1 1 100%',
    },
  },
}));

type OnSubmitCallback = (data: ECRChangeStatus) => void;

type ECRChangeStatusFormProps = Omit<ComponentProps<'form'>, 'onSubmit' | 'children'> & {
  data: ECRDetails;
} & (
    | {
        onSubmit: OnSubmitCallback;
        disabled?: boolean;
      }
    | {
        onSubmit?: OnSubmitCallback;
        disabled: true;
      }
  );

export function ECRChangeStatusForm(props: ECRChangeStatusFormProps): JSX.Element {
  const { data, onSubmit, disabled, ...formProps } = props;
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm<ECRChangeStatus>({
    defaultValues: {
      changeProposal: data.changeProposal ?? '',
      status:
        data.status === ECRStatus.CLOSED || data.status === ECRStatus.SUSPENDED
          ? data.status
          : (null as unknown as ECRChangeStatus['status']),
      statusComments: data.statusComments ?? '',
    },
    resolver: zodResolver(ECRChangeStatusSchema),
  });

  return (
    <StyledForm onSubmit={onSubmit && handleSubmit(onSubmit, console.error)} {...formProps}>
      <Fieldset disabled={disabled}>
        <legend>{t('ecr.section.change_status')}</legend>
        <ResponsiveRow>
          <Controller
            name="changeProposal"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                className="field-change_proposal"
                label={t('ecr.field.change_proposal')}
                error={invalid}
                helperText={error?.message}
                disabled={disabled}
                {...field}
              />
            )}
          />
          <Controller
            name="status"
            control={control}
            render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
              <TextField
                select
                className="field-status"
                label={t('ecr.field.status')}
                value={value ?? ''}
                error={invalid}
                helperText={error?.message}
                disabled={disabled}
                {...field}
              >
                {value === null && <MenuItem>&nbsp;</MenuItem>}
                <MenuItem value={ECRStatus.CLOSED} disabled={data.status !== ECRStatus.ACCEPTED}>
                  {t(`status.${ECRStatus.CLOSED}`)}
                </MenuItem>
                <MenuItem value={ECRStatus.SUSPENDED}>{t(`status.${ECRStatus.SUSPENDED}`)}</MenuItem>
              </TextField>
            )}
          />
          <Controller
            name="statusComments"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                multiline
                minRows={2}
                maxRows={5}
                className="field-status_comments"
                label={t('ecr.field.status_comments')}
                error={invalid}
                helperText={error?.message}
                disabled={disabled}
                {...field}
              />
            )}
          />
        </ResponsiveRow>
      </Fieldset>
    </StyledForm>
  );
}
