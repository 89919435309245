import { type GridColDef, type GridFilterModel, GridLogicOperator, type GridSortModel } from '@mui/x-data-grid-premium';
import {
  DataGrid,
  DataGridWrapper,
  IconButton,
  actionsColDef,
  dateColDef,
  muiFiltersToPagedRequestFilters,
  usePagination,
  usePlantColDef,
  useUserColDef,
} from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDepartmentColDef, usePriorityColDef, useRequestTypeColDef, useStatusColDef } from '../components/DataGrid';
import {
  ECRAnalyseDialog,
  ECRAssignDialog,
  ECRChangeStatusDialog,
  ECRClearStatusDialog,
  ECRCreateDialog,
  ECRDetailsDialog,
  ECRRemoveDialog,
  ECRReopenDialog,
  ECRUpdateDialog,
} from '../components/ECR';
import {
  AnalyseIcon,
  AssignIcon,
  ChangeStatusIcon,
  CreateIcon,
  DetailsIcon,
  RemoveIcon,
  ReopenIcon,
  UpdateIcon,
} from '../components/Icons';
import { Layout } from '../components/Layout';
import type { ECR } from '../entities/ECR';
import { useACL } from '../hooks/useACL';
import { useReadECRListQuery } from '../services/ecrApi';
import { UnauthorizedPage } from './UnauthorizedPage';

export function ECRListPage(): JSX.Element {
  const { t } = useTranslation();
  const acl = useACL();
  const { paginationModel, setPaginationModel } = usePagination(0);
  const [ECRCreateDialogOpen, setECRCreateDialogOpen] = useState(false);
  const [selectedECR, setSelectedECR] = useState<ECR | null>(null);
  const [ECRDetailsDialogOpen, setECRDetailsDialogOpen] = useState(false);
  const [ECRUpdateDialogOpen, setECRUpdateDialogOpen] = useState(false);
  const [ECRRemoveDialogOpen, setECRRemoveDialogOpen] = useState(false);
  const [ECRReopenDialogOpen, setECRReopenDialogOpen] = useState(false);
  const [ECRAssignDialogOpen, setECRAssignDialogOpen] = useState(false);
  const [ECRAnalyseDialogOpen, setECRAnalyseDialogOpen] = useState(false);
  const [ECRChangeStatusDialogOpen, setECRChangeStatusDialogOpen] = useState(false);
  const [ECRClearStatusDialogOpen, setECRClearStatusDialogOpen] = useState(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'openAt', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });

  const readECRListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, sortModel],
  );

  const readECRList = useReadECRListQuery(readECRListParams);

  const usernames = useMemo<string[]>(() => {
    if (readECRList.data) {
      const usernames = new Set<string>();
      for (const ecr of readECRList.data.data) {
        if (ecr.reportedBy) {
          usernames.add(ecr.reportedBy);
        }
        usernames.add(ecr.openBy);
      }
      return Array.from(usernames);
    }
    return [];
  }, [readECRList.data]);

  const userColumn = useUserColDef(usernames);
  const plantColumn = usePlantColDef();
  const priorityColumn = usePriorityColDef();
  const statusColumn = useStatusColDef();
  const departmentColumn = useDepartmentColDef();
  const requestTypeColumn = useRequestTypeColDef();

  const columns = useMemo<GridColDef<ECR>[]>(
    () => [
      { field: 'id', headerName: t('ecr.field.id'), width: 60 },
      statusColumn,
      priorityColumn,
      { ...userColumn, field: 'reportedBy', headerName: t('ecr.field.reported_by') },
      { ...userColumn, field: 'openBy', headerName: t('ecr.field.open_by') },
      plantColumn,
      departmentColumn,
      { field: 'documentId', headerName: t('ecr.field.document_id'), width: 120 },
      requestTypeColumn,
      { ...dateColDef, field: 'openAt', headerName: t('ecr.field.open_at') },
      { ...dateColDef, field: 'assignedAt', headerName: t('ecr.field.assigned_at') },
      { ...dateColDef, field: 'analysedAt', headerName: t('ecr.field.analysed_at') },
      { ...dateColDef, field: 'closedAt', headerName: t('ecr.field.closed_at') },
      { ...dateColDef, field: 'suspendedAt', headerName: t('ecr.field.suspended_at') },
      {
        ...actionsColDef,
        width: 160,
        align: 'left',
        renderHeader: () =>
          acl.canAddECR && (
            <IconButton
              size="small"
              color="primary"
              title={t('ecr.action.create')}
              onClick={() => setECRCreateDialogOpen(true)}
            >
              <CreateIcon />
            </IconButton>
          ),
        renderCell: ({ row }) => (
          <>
            <IconButton
              size="small"
              title={t('ecr.action.details')}
              onClick={() => {
                setSelectedECR(row);
                setECRDetailsDialogOpen(true);
              }}
            >
              <DetailsIcon />
            </IconButton>
            {acl.canAssignECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.assign')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRAssignDialogOpen(true);
                }}
              >
                <AssignIcon />
              </IconButton>
            )}
            {acl.canReopenECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.reopen')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRReopenDialogOpen(true);
                }}
              >
                <ReopenIcon />
              </IconButton>
            )}
            {acl.canAnalyseECR(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.analyse')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRAnalyseDialogOpen(true);
                }}
              >
                <AnalyseIcon />
              </IconButton>
            )}
            {acl.canManageECR(row) && (
              <>
                <IconButton
                  size="small"
                  title={t('ecr.action.update')}
                  onClick={() => {
                    setSelectedECR(row);
                    setECRUpdateDialogOpen(true);
                  }}
                >
                  <UpdateIcon />
                </IconButton>
                <IconButton
                  size="small"
                  title={t('ecr.action.remove')}
                  onClick={() => {
                    setSelectedECR(row);
                    setECRRemoveDialogOpen(true);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
              </>
            )}
            {acl.canChangeECRStatus(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.change_status')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRChangeStatusDialogOpen(true);
                }}
                sx={{ marginInlineStart: 'auto' }}
              >
                <ChangeStatusIcon />
              </IconButton>
            )}
            {acl.canClearECRStatus(row) && (
              <IconButton
                size="small"
                title={t('ecr.action.clear_status')}
                onClick={() => {
                  setSelectedECR(row);
                  setECRClearStatusDialogOpen(true);
                }}
                sx={{ marginInlineStart: 'auto' }}
              >
                <ChangeStatusIcon />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    [acl, departmentColumn, plantColumn, priorityColumn, requestTypeColumn, statusColumn, t, userColumn],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} appId={import.meta.env.VITE_APP_ID}>
      <Layout disableGutters maxWidth={false} inProgress={readECRList.isLoading} error={readECRList.error}>
        <DataGridWrapper>
          <DataGrid
            rows={readECRList.data?.data ?? []}
            rowCount={readECRList.data?.total ?? 0}
            columns={columns}
            sessionStorageId="ECRListPageDataGrid"
            loading={readECRList.isFetching}
            onRefresh={readECRList.refetch}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
          />
        </DataGridWrapper>
        <ECRCreateDialog open={ECRCreateDialogOpen} onClose={() => setECRCreateDialogOpen(false)} />
        {selectedECR && (
          <>
            <ECRDetailsDialog
              open={ECRDetailsDialogOpen}
              onClose={() => setECRDetailsDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRUpdateDialog
              open={ECRUpdateDialogOpen}
              onClose={() => setECRUpdateDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRRemoveDialog
              open={ECRRemoveDialogOpen}
              onClose={() => setECRRemoveDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRAssignDialog
              open={ECRAssignDialogOpen}
              onClose={() => setECRAssignDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRAnalyseDialog
              open={ECRAnalyseDialogOpen}
              onClose={() => setECRAnalyseDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRReopenDialog
              open={ECRReopenDialogOpen}
              onClose={() => setECRReopenDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRChangeStatusDialog
              open={ECRChangeStatusDialogOpen}
              onClose={() => setECRChangeStatusDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
            <ECRClearStatusDialog
              open={ECRClearStatusDialogOpen}
              onClose={() => setECRClearStatusDialogOpen(false)}
              ecr={selectedECR}
              TransitionProps={{ onExited: () => setSelectedECR(null) }}
            />
          </>
        )}
      </Layout>
    </AuthGuard>
  );
}
