import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import type { SxProps, Theme } from '@mui/material/styles';
import type { ComponentProps } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { ECRAnalysis } from '../../../entities/ECRAnalysis';
import { ResponsiveRow } from '../../Layout';
import { Fieldset } from '../../inputs';

type PreAnalysisFieldset = Omit<ComponentProps<'fieldset'>, 'form' | 'children'> & {
  form: UseFormReturn<ECRAnalysis>;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export function PreAnalysisFieldset(props: PreAnalysisFieldset): JSX.Element {
  const {
    form: { control, setValue },
    disabled,
    sx,
  } = props;
  const { t } = useTranslation();

  return (
    <Fieldset sx={sx} disabled={disabled}>
      <legend>{t('ecr.section.pre_analysis')}</legend>
      <ResponsiveRow rowUp="lg">
        <Controller
          control={control}
          name="preAnalysis.accepted"
          render={({ field: { value, onChange, ...field }, fieldState: { error, invalid } }) => {
            return (
              <>
                <TextField
                  select
                  label={t('ecr.field.pre_analysis_rejected')}
                  className="field-pre-rejected"
                  value={value ? 'accepted' : value === false ? 'rejected' : ''}
                  onChange={(e) => {
                    onChange(e.target.value === 'accepted');
                    if (e.target.value === 'rejected') {
                      setValue('analyses', []);
                    }
                  }}
                  error={invalid}
                  helperText={error?.message}
                  disabled={disabled}
                  {...field}
                >
                  {value === null && <MenuItem value="">&nbsp;</MenuItem>}
                  <MenuItem value="rejected">{t('yes')}</MenuItem>
                  <MenuItem value="accepted">{t('no')}</MenuItem>
                </TextField>
              </>
            );
          }}
        />
        <Controller
          control={control}
          name="preAnalysis.reason"
          render={({ field: { value, ...field }, fieldState: { error, invalid } }) => (
            <TextField
              multiline
              maxRows={5}
              label={t('ecr.field.pre_analysis_reason')}
              className="field-pre-reason"
              value={value ?? ''}
              error={invalid}
              helperText={error?.message}
              disabled={disabled}
              {...field}
            />
          )}
        />
      </ResponsiveRow>
    </Fieldset>
  );
}
