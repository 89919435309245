import { AttachmentSchema, PlantSchema } from '@top-solution/microtecnica-utils';
import { z } from 'zod';
import { AnalysisSchema } from './Analysis';
import { DepartmentSchema } from './Department';
import { DocumentTypeSchema } from './DocumentType';
import { PrioritySchema } from './Priority';
import { RequestTypeSchema } from './RequestType';
import { DateTimeSchema } from './utils';

export enum ECRStatus {
  OPEN = 'open',
  ASSIGNED = 'assigned',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  CLOSED = 'closed',
  SUSPENDED = 'suspended',
}

export const ECRSchema = z.object({
  id: z.number(),
  status: z.nativeEnum(ECRStatus),
  plantId: PlantSchema.shape.id,
  departmentId: DepartmentSchema.shape.id,
  documentId: z.string().min(3).max(255),
  reportedBy: z.string().optional(),
  requestTypeId: RequestTypeSchema.shape.id,
  priorityId: PrioritySchema.shape.id,
  openBy: z.string(),
  openAt: DateTimeSchema,
  assignedAt: DateTimeSchema.optional(),
  analysedAt: DateTimeSchema.optional(),
  closedAt: DateTimeSchema.optional(),
  suspendedAt: DateTimeSchema.optional(),
  attachmentList: AttachmentSchema.array().optional(),
});

export type ECR = z.infer<typeof ECRSchema>;

export const ECRDetailsSchema = ECRSchema.extend({
  preAnalysis: z
    .object({
      accepted: z.boolean(),
      reason: z.string().optional(),
    })
    .optional(),
  analyses: AnalysisSchema.array().optional(),
  documentRevision: z.string().min(1).max(255),
  documentTypeId: DocumentTypeSchema.shape.id,
  reason: z.string().min(3).max(4000),
  request: z.string().min(3).max(4000),
  isRequestRejected: z.boolean().optional(),
  changeProposal: z.string().optional(),
  statusComments: z.string().optional(),
});

export type ECRDetails = z.infer<typeof ECRDetailsSchema>;

export const ECRFormSchema = z.object({
  plantId: ECRDetailsSchema.shape.plantId,
  departmentId: ECRDetailsSchema.shape.departmentId,
  documentId: ECRDetailsSchema.shape.documentId,
  documentRevision: ECRDetailsSchema.shape.documentRevision,
  documentTypeId: ECRDetailsSchema.shape.documentTypeId,
  reportedBy: ECRDetailsSchema.shape.reportedBy.unwrap().nullable(),
  requestTypeId: ECRDetailsSchema.shape.requestTypeId,
  priorityId: ECRDetailsSchema.shape.priorityId,
  reason: ECRDetailsSchema.shape.reason,
  request: ECRDetailsSchema.shape.request,
  attachmentList: ECRDetailsSchema.shape.attachmentList.unwrap(),
});

export type ECRForm = z.infer<typeof ECRFormSchema>;
