import { AuthGuard } from '@top-solution/microtecnica-utils';
import { Route, Routes } from 'react-router-dom';
import { ECRUserRole } from '../entities/User';
import { ManageUserListPage } from './ManageUserListPage';
import { ManageValueStreamListPage } from './ManageValueStreamListPage';
import { NotFoundPage } from './NotFoundPage';
import { UnauthorizedPage } from './UnauthorizedPage';

export function AdminRouter(): JSX.Element {
  return (
    <AuthGuard
      appId={import.meta.env.VITE_APP_ID}
      authorizeRole={(role) => role === ECRUserRole.ADMIN}
      unauthorizedFallback={<UnauthorizedPage />}
    >
      <Routes>
        <Route path="users" element={<ManageUserListPage />} />
        <Route path="value_stream" element={<ManageValueStreamListPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthGuard>
  );
}
