import { z } from 'zod';
import { ValueStreamSchema } from './ValueStream';
import { DateTimeSchema } from './utils';

export const AnalysisSchema = z.object({
  id: z.number(),
  equipmentCode: z.string().min(3),
  valueStreamId: ValueStreamSchema.shape.id,
  assessment: z.string().max(4000).optional(),
  analyzedAt: DateTimeSchema.optional(),
  accepted: z.boolean().optional(),
});

export type Analysis = z.infer<typeof AnalysisSchema>;
