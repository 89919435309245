import { type RequestType, RequestTypeSchema } from '../entities/RequestType';
import { api } from './baseApi';

const url = 'v1/request-type';

const requestTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readRequestTypeList: builder.query<RequestType[], void>({
      query: () => ({ url }),
      transformResponse: (data) => RequestTypeSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadRequestTypeListQuery } = requestTypeApi;
