import type { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ECR, ECRStatus } from '../../entities/ECR';
import { StatusChip } from '../ECR';

export function useStatusColDef(): GridSingleSelectColDef<ECR, ECR['status'], string> {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      field: 'status',
      headerName: t('ecr.field.status'),
      type: 'singleSelect',
      width: 120,
      valueOptions: () => Object.values(ECRStatus).map((status) => ({ value: status, label: t(`status.${status}`) })),
      renderCell: ({ value }) => value && <StatusChip status={value} size="small" />,
    }),
    [t],
  );
}
