import { type DocumentType, DocumentTypeSchema } from '../entities/DocumentType';
import { api } from './baseApi';

const url = 'v1/document-type';

const documentTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readDocumentTypeList: builder.query<DocumentType[], void>({
      query: () => ({ url }),
      transformResponse: (data) => DocumentTypeSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadDocumentTypeListQuery } = documentTypeApi;
