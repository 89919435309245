import { NotFoundBox } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';

export function NotFoundPage(): JSX.Element {
  const { t } = useTranslation();
  const title = t('error.not_found');
  return (
    <Layout title={title} maxWidth="sm">
      <NotFoundBox title={title} />
    </Layout>
  );
}
