import Container, { type ContainerProps } from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import {
  type Breadcrumb,
  FullPageProgress,
  Header,
  type MenuItem,
  ServerErrorBox,
} from '@top-solution/microtecnica-mui';
import { type ApiError, CountryFlag, supportedLanguages } from '@top-solution/microtecnica-utils';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useACL } from '../../hooks/useACL';
import { UserManagementIcon } from '../Icons';

const StyledContainer = styled(Container)({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
});

interface LayoutProps extends ContainerProps {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  error?: ApiError | Error;
  inProgress?: boolean;
  progressIndicator?: React.ReactNode;
}

const APP_NAME = import.meta.env.VITE_NAME as string;

export function Layout(props: LayoutProps): JSX.Element {
  const { children, title, breadcrumbs, inProgress, error, progressIndicator, ...containerProps } = props;
  const acl = useACL();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const pageTitle = useMemo(() => {
    let pageTitle: string | null = null;
    if (title) {
      pageTitle = title;
    } else if (breadcrumbs?.length) {
      pageTitle = breadcrumbs.reduce(
        (previousTitle, breadcrumb) => {
          if (typeof breadcrumb.title === 'string') {
            return breadcrumb.title;
          }
          return previousTitle;
        },
        null as string | null,
      );
    }
    if (pageTitle) {
      return `${pageTitle} | ${APP_NAME}`;
    }
    return APP_NAME;
  }, [breadcrumbs, title]);

  const menuItems = useMemo(() => {
    const menuItems = new Array<MenuItem | JSX.Element>();
    if (acl.canManageUsers) {
      menuItems.push({
        label: t('pages.value_stream_management.title'),
        icon: <UserManagementIcon />,
        onClick: (close) => {
          navigate('/admin/value_stream');
          close();
        },
      });
      menuItems.push({
        label: t('app_user_management.title'),
        icon: <UserManagementIcon />,
        onClick: (close) => {
          navigate('/admin/users');
          close();
        },
      });
    }
    if (menuItems.length > 0) {
      menuItems.push(<Divider key="admin-divider" />);
    }
    menuItems.push(
      ...supportedLanguages.map(
        (language) =>
          ({
            label: t(`language.${language}`),
            onClick: (close) => {
              i18n.changeLanguage(language);
              close();
            },
            icon: <CountryFlag countryCode={language === 'en' ? 'GB' : language.toUpperCase()} />,
            disabled: language === i18n.language,
          }) as MenuItem,
      ),
    );
    return menuItems;
  }, [acl, i18n, navigate, t]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header
        logo={{ title: APP_NAME }}
        position="fixed"
        breadcrumbs={breadcrumbs}
        smallScreenBreakpoint={theme.breakpoints.values.sm}
        menuItems={menuItems}
      />
      <Toolbar />
      <StyledContainer {...containerProps}>
        {inProgress ? (
          progressIndicator || <FullPageProgress />
        ) : error ? (
          <ServerErrorBox error={error} sx={{ maxWidth: 'sm', marginX: 'auto' }} />
        ) : (
          children
        )}
      </StyledContainer>
    </>
  );
}
