import type { GridColDef } from '@mui/x-data-grid-premium';
import { skipToken } from '@reduxjs/toolkit/query';
import { DataGrid, DataGridWrapper, IconButton, actionsColDef } from '@top-solution/microtecnica-mui';
import { useSearchUserListQuery } from '@top-solution/microtecnica-utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateIcon } from '../components/Icons';
import { Layout } from '../components/Layout';
import { ValueStreamUpdateDialog } from '../components/ValueStream';
import type { ValueStream } from '../entities/ValueStream';
import { useReadValueStreamListQuery } from '../services/valueStreamApi';

type ValueStreamRow = {
  id: ValueStream['id'];
  username: ValueStream['manager'];
  lastname?: string;
  firstname?: string;
  email?: string;
};

export function ManageValueStreamListPage(): JSX.Element {
  const { t } = useTranslation();
  const title = t('pages.value_stream_management.title');

  const [selectedValueStream, setSelectedValueStream] = useState<ValueStream | null>(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const readValueSreamList = useReadValueStreamListQuery();

  const managerList = useMemo(() => readValueSreamList.data?.map(({ manager }) => manager), [readValueSreamList.data]);
  const readManagerList = useSearchUserListQuery(managerList ? { usernames: managerList } : skipToken);

  const rows = useMemo<ValueStreamRow[]>(() => {
    if (readManagerList.data && readValueSreamList.data) {
      return readValueSreamList.data.map((valueStream) => {
        const manager = readManagerList.data?.find((user) => user.username === valueStream.manager);
        return {
          id: valueStream.id,
          lastname: manager?.lastname,
          firstname: manager?.firstname,
          email: manager?.email,
          username: valueStream.manager,
        };
      });
    }
    return [];
  }, [readValueSreamList.data, readManagerList.data]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        headerName: t('value_stream.field.id'),
        minWidth: 150,
        valueFormatter: (value) => value && t(`value_stream.${value as string}`),
      },
      { field: 'lastname', headerName: t('entity.user.field.lastname'), width: 150 },
      { field: 'firstname', headerName: t('entity.user.field.firstname'), width: 150 },
      { field: 'username', headerName: t('entity.user.field.username'), width: 150 },
      { field: 'email', headerName: t('entity.user.field.email'), width: 400 },
      {
        ...actionsColDef,
        width: 80,
        renderCell: ({ row }) => (
          <IconButton
            title={t('action.update')}
            size="small"
            onClick={() => {
              setSelectedValueStream({ id: row.id, manager: row.username });
              setUpdateDialogOpen(true);
            }}
          >
            <UpdateIcon fontSize="small" />
          </IconButton>
        ),
      },
    ],
    [t],
  );

  return (
    <Layout
      title={title}
      disableGutters
      maxWidth={false}
      inProgress={readValueSreamList.isLoading || readManagerList.isLoading}
      error={readValueSreamList.error || readManagerList.error}
    >
      <DataGridWrapper>
        <DataGrid
          columns={columns}
          rows={rows}
          loading={readValueSreamList.isFetching}
          columnGroupingModel={[
            {
              groupId: t('value_stream.field.manager'),
              children: [{ field: 'lastname' }, { field: 'firstname' }, { field: 'username' }, { field: 'email' }],
            },
          ]}
        />
      </DataGridWrapper>
      {selectedValueStream && (
        <ValueStreamUpdateDialog
          open={updateDialogOpen}
          onClose={() => setUpdateDialogOpen(false)}
          valueStream={selectedValueStream}
          TransitionProps={{ onExited: () => setSelectedValueStream(null) }}
        />
      )}
    </Layout>
  );
}
