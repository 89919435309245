import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

type ECRFieldProps = ComponentPropsWithoutRef<'div'> & {
  field: string;
  context?: string;
};

export function ECRField({
  field,
  context = 'ecr.field',
  children,
  className,
  ...otherProps
}: ECRFieldProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={clsx(['ECRField', field, className])} {...otherProps}>
      <dt>{t(`${context}.${field}`)}</dt>
      <dd>{children ?? '–'}</dd>
    </div>
  );
}
