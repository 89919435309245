import type { TextFieldProps } from '@mui/material/TextField';
import { EntitySelect } from '@top-solution/microtecnica-mui';
import { type Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadValueStreamListQuery } from '../../services/valueStreamApi';

type ValueStreamSelectProps = Omit<TextFieldProps, 'select' | 'children'>;

export const ValueStreamSelect = forwardRef(function ValueStreamSelect(
  props: ValueStreamSelectProps,
  ref: Ref<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const readValueStreamList = useReadValueStreamListQuery();

  return (
    <EntitySelect
      ref={ref}
      options={readValueStreamList.data}
      fetchError={readValueStreamList.isError}
      fetchInProgress={readValueStreamList.isFetching}
      getValue={(item) => item.id}
      getLabel={(item) => t(`value_stream.${item.id}`)}
      {...props}
    />
  );
});
