import { type Priority, PrioritySchema } from '../entities/Priority';
import { api } from './baseApi';

const url = 'v1/priority';

const priorityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPriorityList: builder.query<Priority[], void>({
      query: () => ({ url }),
      transformResponse: (data) => PrioritySchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadPriorityListQuery } = priorityApi;
