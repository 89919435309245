import type { TextFieldProps } from '@mui/material/TextField';
import { EntitySelect } from '@top-solution/microtecnica-mui';
import { type Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadRequestTypeListQuery } from '../../services/requestTypeApi';

type RequestTypeSelectProps = Omit<TextFieldProps, 'select' | 'children'>;

export const RequestTypeSelect = forwardRef(function RequestTypeSelect(
  props: RequestTypeSelectProps,
  ref: Ref<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const readRequestTypeList = useReadRequestTypeListQuery();

  return (
    <EntitySelect
      ref={ref}
      options={readRequestTypeList.data}
      fetchError={readRequestTypeList.isError}
      fetchInProgress={readRequestTypeList.isFetching}
      getValue={(item) => item.id}
      getLabel={(item) => t(`request_type.${item.name}`)}
      {...props}
    />
  );
});
