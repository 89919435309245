import Skeleton from '@mui/material/Skeleton';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { RequestType } from '../../../entities/RequestType';
import { useReadRequestTypeListQuery } from '../../../services/requestTypeApi';

type RequestTypeLabelProps = {
  requestTypeId: RequestType['id'];
};

export function RequestTypeLabel({ requestTypeId }: RequestTypeLabelProps): ReactNode {
  const { t } = useTranslation();
  const readRequestTypeList = useReadRequestTypeListQuery();

  const requestType = useMemo(
    () => readRequestTypeList.data?.find((requestType) => requestType.id === requestTypeId),
    [requestTypeId, readRequestTypeList.data],
  );

  if (readRequestTypeList.isLoading) {
    return <Skeleton variant="text" width="10ch" />;
  }
  return t(`request_type.${requestType?.name ?? requestTypeId}`);
}
