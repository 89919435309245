import { ConfirmDialog, type ConfirmDialogProps } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useReopenECRMutation } from '../../services/ecrApi';

export type ECRReopenDialogProps = Omit<
  ConfirmDialogProps,
  'title' | 'confirmText' | 'inProgress' | 'error' | 'onConfirm'
> & {
  ecr: ECR | null;
};

export function ECRReopenDialog(props: ECRReopenDialogProps): JSX.Element {
  const { ecr, open, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const [reopen, reopenStatus] = useReopenECRMutation();

  return (
    <ConfirmDialog
      fullWidth
      maxWidth="xs"
      title={t('ecr.reopen.confirm.title', { id: ecr?.id })}
      description={t('ecr.reopen.confirm.description')}
      confirmText={ecr?.id.toString()}
      inProgress={reopenStatus.isLoading}
      error={reopenStatus.error}
      open={open}
      onClose={(e) => onClose(e, 'cancelClick')}
      onConfirm={async (e) => {
        if (ecr) {
          await reopen(ecr).unwrap();
          onClose(e, 'submitClick');
        }
      }}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          reopenStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    />
  );
}
