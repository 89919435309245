import { styled } from '@mui/material/styles';

export const ECRDataWrapper = styled('div')(({ theme }) => ({
  h4: {
    ...theme.typography.subtitle1,
    margin: 0,
  },

  dl: {
    margin: 0,
    display: 'flex',
    flexFlow: 'row wrap',
    color: theme.palette.text.secondary,
    borderBlockEnd: `thin solid ${theme.palette.divider}`,
    borderInlineEnd: `thin solid ${theme.palette.divider}`,

    '&>div': {
      padding: theme.spacing(0.5, 1),
      borderBlockStart: `thin solid ${theme.palette.divider}`,
      borderInlineStart: `thin solid ${theme.palette.divider}`,
      borderCollapse: 'collapse',
    },
  },

  dt: {
    ...theme.typography.subtitle2,
  },

  dd: {
    margin: 0,
  },

  '.ECRField': {
    flex: '1 1 100%',
  },
}));
