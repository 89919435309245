import type { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useReadPriorityListQuery } from '../../services/priorityApi';

export function usePriorityColDef(): GridSingleSelectColDef<ECR, ECR['priorityId'], string> {
  const { data, isLoading } = useReadPriorityListQuery();
  const { t } = useTranslation();

  return useMemo(
    () => ({
      field: 'priorityId',
      headerName: t('ecr.field.priority'),
      type: 'singleSelect',
      valueOptions: data?.map(({ id, name }) => ({ value: id, label: t(`priority.${name}`) })) ?? [],
      filterable: !isLoading,
    }),
    [data, isLoading, t],
  );
}
