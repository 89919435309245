import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { ErrorAlert, UserAutocomplete } from '@top-solution/microtecnica-mui';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { type ValueStream, ValueStreamSchema } from '../../entities/ValueStream';
import { useUpdateValueStreamMutation } from '../../services/valueStreamApi';

type ValueStreamUpdateDialogProps = Omit<DialogProps, 'onClose' | 'children'> & {
  valueStream: ValueStream;
  onClose: (valueStream?: ValueStream) => void;
};

const ValueStreamUpdateSchema = ValueStreamSchema.extend({
  manager: z.string().min(3),
});

export function ValueStreamUpdateDialog(props: ValueStreamUpdateDialogProps) {
  const { valueStream, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const [update, updateStatus] = useUpdateValueStreamMutation();

  const { handleSubmit, control, reset } = useForm<ValueStream>({
    defaultValues: valueStream,
    resolver: zodResolver(ValueStreamUpdateSchema),
  });

  const onSubmit = useCallback(
    async (data: ValueStream) => {
      await update(data).unwrap();
      onClose(data);
    },
    [onClose, update],
  );

  return (
    <Dialog
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="sm"
      fullWidth
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          reset();
          updateStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('value_stream.action.update', { id: valueStream.id })}</DialogTitle>
      <DialogContent>
        <Stack gap={1} marginBlockStart={1}>
          <Controller
            control={control}
            name="manager"
            render={({ field: { onChange, ...field }, fieldState: { invalid, error } }) => (
              <UserAutocomplete
                label={t('value_stream.field.manager')}
                fullWidth
                error={invalid}
                helperText={error?.message ?? ' '}
                onChange={(_, value) => onChange(value)}
                {...field}
              />
            )}
          />
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary">{t('action.cancel')}</Button>
        <LoadingButton variant="contained" color="primary" type="submit" loading={updateStatus.isLoading}>
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
