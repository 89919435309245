import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { ErrorAlert, FullPageProgress } from '@top-solution/microtecnica-mui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import type { ECRChangeStatus } from '../../entities/ECRChangeStatus';
import { useChangeECRStatusMutation, useReadECRQuery } from '../../services/ecrApi';
import { ECRChangeStatusForm } from './ECRChangeStatusForm';
import { ECRAnalysis, ECRData } from './ECRData';

const formId = 'ecr-change-status';

const FormWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: theme.spacing(2),
  },

  '& > *': {
    flex: 1,
  },
}));

type ECRChangeStatusDialogProps = Omit<DialogProps, 'onClose'> & {
  ecr: ECR;
  onClose: () => void;
};

export function ECRChangeStatusDialog(props: ECRChangeStatusDialogProps): JSX.Element {
  const { ecr, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const readECRDetails = useReadECRQuery(ecr.id);
  const [changeStatus, changeStatusState] = useChangeECRStatusMutation();

  const handleSubmit = useCallback(
    async (data: ECRChangeStatus) => {
      await changeStatus({ id: ecr.id, ...data }).unwrap();
      onClose();
    },
    [changeStatus, ecr.id, onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          changeStatusState.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('ecr.action.change_status')}</DialogTitle>
      <DialogContent>
        {readECRDetails.isLoading ? (
          <FullPageProgress height={450} />
        ) : readECRDetails.error ? (
          <ErrorAlert error={readECRDetails.error} />
        ) : (
          readECRDetails.data && (
            <FormWrapper>
              <div>
                <ECRChangeStatusForm data={readECRDetails.data} id={formId} onSubmit={handleSubmit} />
                <ECRData ecr={readECRDetails.data} displayPreAnalysis sx={{ marginBlockStart: 1 }} />
              </div>
              <ECRAnalysis ecr={readECRDetails.data} />
            </FormWrapper>
          )
        )}
        {changeStatusState.error && <ErrorAlert error={changeStatusState.error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          {t('action.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          form={formId}
          loading={changeStatusState.isLoading}
          disabled={readECRDetails.isLoading}
        >
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
