import { type Department, DepartmentSchema } from '../entities/Department';
import { api } from './baseApi';

const url = 'v1/department';

const departmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readDepartmentList: builder.query<Department[], void>({
      query: () => ({ url }),
      transformResponse: (data) => DepartmentSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadDepartmentListQuery } = departmentApi;
