import { type Equipment, EquipmentSchema } from '../entities/Equipment';
import { api } from './baseApi';

const url = 'v1/equipment';

const equipmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readEquipmentList: builder.query<Equipment[], void>({
      query: () => ({ url }),
      transformResponse: (data) => EquipmentSchema.array().parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useReadEquipmentListQuery } = equipmentApi;
