import Skeleton from '@mui/material/Skeleton';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { DocumentType } from '../../../entities/DocumentType';
import { useReadDocumentTypeListQuery } from '../../../services/documentTypeApi';

type DocumentTypeLabelProps = {
  documentTypeId: DocumentType['id'];
};

export function DocumentTypeLabel({ documentTypeId }: DocumentTypeLabelProps): ReactNode {
  const { t } = useTranslation();
  const readDocumentTypeList = useReadDocumentTypeListQuery();

  const documentType = useMemo(
    () => readDocumentTypeList.data?.find((documentType) => documentType.id === documentTypeId),
    [documentTypeId, readDocumentTypeList.data],
  );

  if (readDocumentTypeList.isLoading) {
    return <Skeleton variant="text" width="10ch" />;
  }
  return t(`document_type.${documentType?.name ?? documentTypeId}`);
}
