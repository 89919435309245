import { type SxProps, type Theme, styled } from '@mui/material/styles';
import { formatDateTime } from '@top-solution/microtecnica-utils';
import { useTranslation } from 'react-i18next';
import type { ECRDetails } from '../../../entities/ECR';
import { ECRDataWrapper } from './ECRDataWrapper';
import { ECRField } from './ECRField';

const AnalysisWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(0, 0, 1, 0),

  h5: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  '.ECRField': {
    [['&.equipment_code', '&.value_stream', '&.accepted', '&.analyzed_at'].join(',')]: {
      flex: '1 1 50%',
    },
  },
}));

type ECRAnalysisProps = {
  ecr: ECRDetails;
  sx?: SxProps<Theme>;
};

export function ECRAnalysis(props: ECRAnalysisProps): JSX.Element {
  const { ecr, sx } = props;
  const { t } = useTranslation();

  return (
    <ECRDataWrapper sx={sx}>
      {ecr.analyses?.map((analysis, index) => (
        <AnalysisWrapper key={analysis.id}>
          <h5>{t('ecr.field.analysis_index', { index })}</h5>
          <dl>
            <ECRField field={'equipment_code'} context="equipment.field">
              {analysis.equipmentCode}
            </ECRField>
            <ECRField field={'value_stream'} context="equipment.field">
              {t(`value_stream.${analysis.valueStreamId}`)}
            </ECRField>
            <ECRField field={'accepted'} context="equipment.field">
              {analysis.accepted === true ? t('yes') : analysis.accepted === false ? t('no') : undefined}
            </ECRField>
            <ECRField field={'analyzed_at'} context="equipment.field">
              {analysis.analyzedAt ? formatDateTime(analysis.analyzedAt) : undefined}
            </ECRField>
            <ECRField field={'assessment'} context="equipment.field">
              {analysis.assessment}
            </ECRField>
          </dl>
        </AnalysisWrapper>
      ))}
    </ECRDataWrapper>
  );
}
