import { type Attachment, AttachmentSchema } from '@top-solution/microtecnica-utils';
import { api } from './baseApi';

const url = 'v1/attachments';

const attachmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation<Attachment, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (data) => AttachmentSchema.parse(data),
    }),
  }),
  overrideExisting: false,
});

export const { useUploadMutation } = attachmentApi;
