import type { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useReadDepartmentListQuery } from '../../services/departmentApi';

export function useDepartmentColDef(): GridSingleSelectColDef<ECR, ECR['departmentId'], string> {
  const { data, isLoading } = useReadDepartmentListQuery();
  const { t } = useTranslation();

  return useMemo(
    () => ({
      field: 'departmentId',
      headerName: t('ecr.field.department'),
      type: 'singleSelect',
      valueOptions: data?.map(({ id, name }) => ({ value: id, label: t(`department.${name}`) })) ?? [],
      filterable: !isLoading,
      width: 150,
    }),
    [data, isLoading, t],
  );
}
