import Chip, { type ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECRStatus } from '../../../entities/ECR';
import colors from './colors.json';

type StatusChipProps = Omit<ChipProps, 'color' | 'label'> & {
  status: ECRStatus;
};

const StyledChip = styled(Chip)<StatusChipProps>(({ theme, status }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: colors[status]?.background,
  color: colors[status]?.color,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
}));

function StatusChipComponent({ status, ...props }: StatusChipProps) {
  const { t } = useTranslation();
  return <StyledChip status={status} className={status} {...props} label={t(`status.${status}`)} />;
}

export const StatusChip = memo(StatusChipComponent);
