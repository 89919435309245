import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider as DatePickersLocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { LicenseInfo } from '@mui/x-license';
import { LocalizationProvider } from '@top-solution/microtecnica-mui';
import { CommonDataApiProvider, setAuthApiUrl } from '@top-solution/microtecnica-utils';
import { it as dateAdapterLocale } from 'date-fns/locale/it';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App.tsx';
import { store } from './store';
import { theme } from './theme';
import { en, it } from './translations';
// CSS
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

const appId = import.meta.env.VITE_APP_ID;
const appVersion = import.meta.env.VITE_VERSION;
const authApi = import.meta.env.VITE_AUTH_API;

console.info(`🚀 ${appId} (v. ${appVersion}) 🚀`);

setAuthApiUrl(`${authApi}/api`);

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const appTranslations = [
  { language: 'en', bundle: { translation: en } },
  { language: 'it', bundle: { translation: it } },
];

root.render(
  <StrictMode>
    <LocalizationProvider additionalBundles={appTranslations}>
      <DatePickersLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateAdapterLocale}>
        <CommonDataApiProvider value={{ appIDList: [appId], authApi }}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <BrowserRouter>
                <HelmetProvider>
                  <App />
                </HelmetProvider>
              </BrowserRouter>
            </ThemeProvider>
          </Provider>
        </CommonDataApiProvider>
      </DatePickersLocalizationProvider>
    </LocalizationProvider>
  </StrictMode>,
);
