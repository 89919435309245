import TextField from '@mui/material/TextField';
import { skipToken } from '@reduxjs/toolkit/query';
import { getDisplayName, useReadUserQuery } from '@top-solution/microtecnica-utils';
import { type Ref, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadEquipmentListQuery } from '../../../services/equipmentApi';
import { EquipmentAutocomplete, type EquipmentAutocompleteProps } from '../../inputs/EquipmentAutocomplete';

type EquipmentAutocompleteWithManagerPropsLabel = EquipmentAutocompleteProps;

export function EquipmentAutocompleteWithManagerLabelComponent(
  props: EquipmentAutocompleteWithManagerPropsLabel,
  ref: Ref<unknown>,
) {
  const { t } = useTranslation();

  const readEquipmentList = useReadEquipmentListQuery();

  const equipment = useMemo(
    () => readEquipmentList.data?.find((equipment) => equipment.id === props.value),
    [props.value, readEquipmentList.data],
  );

  const readUser = useReadUserQuery(equipment ? { username: equipment.username } : skipToken);

  return (
    <>
      <EquipmentAutocomplete label={t('equipment.field.equipment_code')} {...props} ref={ref} />
      <TextField
        label={t('equipment.field.manager')}
        value={readUser.data ? getDisplayName(readUser.data) : ''}
        disabled
      />
    </>
  );
}

export const EquipmentAutocompleteWithManagerLabel = forwardRef(EquipmentAutocompleteWithManagerLabelComponent);
