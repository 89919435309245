import { AppUserListGrid } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/Layout';

export function ManageUserListPage(): JSX.Element {
  const { t } = useTranslation();
  const title = t('app_user_management.title');
  return (
    <Layout title={title} disableGutters maxWidth={false}>
      <AppUserListGrid appId={import.meta.env.VITE_APP_ID} />
    </Layout>
  );
}
