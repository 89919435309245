import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import type { Attachment } from '@top-solution/microtecnica-utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECRForm } from '../../entities/ECR';
import { useCreateECRMutation } from '../../services/ecrApi';
import { ECREditForm } from './ECREditForm';

const createECRDefaultsLocalStorageKey = 'add-ecr-defaults';

const formId = 'ecr-add-form';
const emptyECR: ECRForm = {
  plantId: '' as unknown as number,
  departmentId: '' as unknown as number,
  documentId: '',
  documentRevision: '',
  documentTypeId: '' as unknown as number,
  reportedBy: null,
  requestTypeId: '' as unknown as number,
  priorityId: '' as unknown as number,
  reason: '',
  request: '',
  attachmentList: [] as Attachment[],
};

type ECRCreateDialogProps = Omit<DialogProps, 'onClose' | 'children'> & {
  onClose: (ecr?: ECRForm) => void;
};

export function ECRCreateDialog(props: ECRCreateDialogProps): JSX.Element {
  const { onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const [create, createStatus] = useCreateECRMutation();

  const onSubmit = useCallback(
    async (data: ECRForm) => {
      localStorage.setItem(
        createECRDefaultsLocalStorageKey,
        JSON.stringify({
          plantId: data.plantId,
          departmentId: data.departmentId,
        }),
      );
      await create(data).unwrap();
      onClose(data);
    },
    [create, onClose],
  );

  const initialValues = useMemo(() => {
    const defaults = localStorage.getItem(createECRDefaultsLocalStorageKey);
    if (defaults) {
      return { ...emptyECR, ...JSON.parse(defaults) };
    }
    return emptyECR;
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={() => onClose()}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          createStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('ecr.action.create')}</DialogTitle>
      <DialogContent>
        <ECREditForm id={formId} initialValues={initialValues} onSubmit={onSubmit} />
        {createStatus.error && <ErrorAlert error={createStatus.error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          {t('action.cancel')}
        </Button>
        <LoadingButton color="primary" variant="contained" type="submit" form={formId} loading={createStatus.isLoading}>
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
