import { DeleteConfirmDialog, type DeleteConfirmDialogProps } from '@top-solution/microtecnica-mui';
import { useTranslation } from 'react-i18next';
import type { ECR } from '../../entities/ECR';
import { useRemoveECRMutation } from '../../services/ecrApi';

export type ECRRemoveDialogProps = Omit<
  DeleteConfirmDialogProps,
  'title' | 'confirmText' | 'inProgress' | 'error' | 'onConfirm'
> & {
  ecr: ECR | null;
};

export function ECRRemoveDialog(props: ECRRemoveDialogProps): JSX.Element {
  const { ecr, open, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const [remove, removeStatus] = useRemoveECRMutation();

  return (
    <DeleteConfirmDialog
      fullWidth
      maxWidth="xs"
      title={t('ecr.remove.confirm.title', { id: ecr?.id })}
      confirmText={ecr?.id.toString()}
      inProgress={removeStatus.isLoading}
      error={removeStatus.error}
      open={open}
      onClose={(e) => onClose(e, 'cancelClick')}
      onConfirm={async (e) => {
        if (ecr) {
          await remove(ecr).unwrap();
          onClose(e, 'submitClick');
        }
      }}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          removeStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    />
  );
}
