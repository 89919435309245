import secondary from '@mui/material/colors/blueGrey';
import primary from '@mui/material/colors/deepPurple';
import { itIT as coreItIT } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { itIT as gridItIT } from '@mui/x-data-grid-premium/locales';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

export const theme = createTheme(
  {
    palette: {
      primary,
      secondary,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
    },
  },
  gridItIT,
  coreItIT,
);
