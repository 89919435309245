import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { type DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { skipToken } from '@reduxjs/toolkit/query';
import { ErrorAlert, FullPageProgress } from '@top-solution/microtecnica-mui';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECRForm } from '../../entities/ECR';
import type { ECR } from '../../entities/ECR';
import { useUpdateECRMutation } from '../../services/ecrApi';
import { useReadECRQuery } from '../../services/ecrApi';
import { ECREditForm } from './ECREditForm';
const formId = 'ecr-update-form';

type ECRUpdateDialogProps = Omit<DialogProps, 'onClose'> & {
  ecr: ECR;
  onClose: () => void;
};

export function ECRUpdateDialog(props: ECRUpdateDialogProps): JSX.Element {
  const { ecr, onClose, TransitionProps, ...dialogProps } = props;
  const { t } = useTranslation();
  const readECRDetails = useReadECRQuery(ecr?.id ?? skipToken);
  const [update, updateStatus] = useUpdateECRMutation();

  const onSubmit = useCallback(
    async (data: ECRForm) => {
      if (ecr) {
        await update({ id: ecr.id, ...data }).unwrap();
        onClose();
      }
    },
    [ecr, update, onClose],
  );

  const initialValues = useMemo(
    () =>
      readECRDetails.data
        ? {
            plantId: readECRDetails.data.plantId,
            departmentId: readECRDetails.data.departmentId,
            documentId: readECRDetails.data.documentId,
            documentRevision: readECRDetails.data.documentRevision,
            documentTypeId: readECRDetails.data.documentTypeId,
            reportedBy: readECRDetails.data.reportedBy ?? null,
            requestTypeId: readECRDetails.data.requestTypeId,
            priorityId: readECRDetails.data.priorityId,
            reason: readECRDetails.data.reason,
            request: readECRDetails.data.request,
            attachmentList: readECRDetails.data.attachmentList ?? [],
          }
        : null,
    [readECRDetails.data],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          updateStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
      {...dialogProps}
    >
      <DialogTitle>{t('ecr.action.update')}</DialogTitle>
      <DialogContent>
        {readECRDetails.isLoading ? (
          <FullPageProgress height={500} />
        ) : readECRDetails.error ? (
          <ErrorAlert error={readECRDetails.error} />
        ) : (
          initialValues && <ECREditForm id={formId} initialValues={initialValues} onSubmit={onSubmit} />
        )}
        {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          {t('action.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          form={formId}
          loading={updateStatus.isLoading}
          disabled={readECRDetails.isLoading}
        >
          {t('action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
