import type { TextFieldProps } from '@mui/material/TextField';
import { EntitySelect } from '@top-solution/microtecnica-mui';
import { type Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadPriorityListQuery } from '../../services/priorityApi';

type PrioritySelectProps = Omit<TextFieldProps, 'select' | 'children'>;

export const PrioritySelect = forwardRef(function PrioritySelect(props: PrioritySelectProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();
  const readPriorityList = useReadPriorityListQuery();

  return (
    <EntitySelect
      ref={ref}
      options={readPriorityList.data}
      fetchError={readPriorityList.isError}
      fetchInProgress={readPriorityList.isFetching}
      getValue={(item) => item.id}
      getLabel={(item) => t(`priority.${item.name}`)}
      {...props}
    />
  );
});
